import React from 'react';
function Caret(props) {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 8 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path d="M1 1L4.05325 4L7 1" stroke="#00000A" strokeWidth="1.2" />
    </svg>
  );
}

export default Caret;
