import React from 'react';
import PropTypes from 'prop-types';
function VymoLogo({ dark }) {
  const color = dark ? '#00000A' : '#ffffff';
  return (
    <svg
      width="103"
      height="38"
      viewBox="0 0 103 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2715_2521)">
        <path
          d="M53.6736 25.1844L59.7283 10.0547H57.4044L53.0214 21.4019L48.6383 10.0547H46.2734L52.3486 25.1844H53.6736Z"
          fill={color}
        />
        <path
          d="M64.6143 28.9666L70.8525 13.8574H68.5693L65.1648 22.649L61.8418 13.8574H59.5586L64.0639 24.9593L62.4127 28.9666H64.6143Z"
          fill={color}
        />
        <path
          d="M89.0992 25.1843V18.3146C89.0992 15.3908 87.4275 13.5508 84.492 13.5508C82.698 13.5508 81.5767 14.1641 80.7613 15.4522C80.1904 14.2664 78.7228 13.5508 77.2549 13.5508C75.7871 13.5508 74.9309 14.0006 74.0135 15.0842H73.9728V13.8575H72.0156V25.1843H74.1359V18.8257C74.1359 16.5972 75.0533 15.4931 76.9287 15.4931C78.4576 15.4931 79.4974 16.3723 79.4974 18.6008V25.1843H81.6175V18.8053C81.6175 16.5358 82.433 15.4931 84.3085 15.4931C86.0821 15.4931 86.9791 16.6994 86.9791 18.8461V25.1843H89.0992Z"
          fill={color}
        />
        <path
          d="M102.999 19.6027C103.04 16.0043 100.105 13.5508 96.9855 13.5508C93.5199 13.5508 90.8086 16.1065 90.8086 19.5617C90.8086 22.7513 93.6422 25.4705 96.8224 25.4705C100.431 25.4705 102.959 22.6286 102.999 19.6027ZM100.879 19.48C100.881 20.0152 100.777 20.5453 100.574 21.0395C100.372 21.5338 100.074 21.9823 99.6981 22.359C99.3225 22.7357 98.8767 23.033 98.3865 23.2337C97.8963 23.4344 97.3716 23.5345 96.8429 23.5281C94.7023 23.5281 92.9287 21.6472 92.9287 19.5005C92.9398 18.4419 93.3599 17.4299 94.0991 16.6809C94.8382 15.9319 95.8377 15.5055 96.8836 15.4931C97.4051 15.4875 97.9226 15.5864 98.4062 15.7841C98.8897 15.9818 99.3299 16.2744 99.7013 16.645C100.073 17.0156 100.368 17.4568 100.57 17.9434C100.772 18.43 100.877 18.9522 100.879 19.48Z"
          fill={color}
        />
        <path
          d="M17.6343 26.575C17.8071 26.5766 17.9784 26.5433 18.1383 26.4771C18.2982 26.4109 18.4434 26.3131 18.5655 26.1895C18.4447 26.3159 18.3001 26.4165 18.1401 26.4852C17.9801 26.5539 17.8081 26.5892 17.6343 26.5892C17.4605 26.5892 17.2885 26.5539 17.1286 26.4852C16.9686 26.4165 16.8239 26.3159 16.7031 26.1895C16.8252 26.3131 16.9705 26.4109 17.1304 26.4771C17.2903 26.5433 17.4616 26.5766 17.6343 26.575Z"
          fill={color}
        />
        <path
          d="M34.1983 0H2.82192C1.26342 0 0 1.27867 0 2.85599V34.2719C0 35.8492 1.26342 37.1279 2.82192 37.1279H34.1983C35.7568 37.1279 37.0202 35.8492 37.0202 34.2719V2.85599C37.0202 1.27867 35.7568 0 34.1983 0Z"
          fill="#FF5A5F"
        />
        <path
          d="M18.9466 19.0921V25.2611C18.9432 25.6088 18.8066 25.9416 18.5656 26.1893C18.3171 26.4364 17.9827 26.5748 17.6344 26.5748C17.2861 26.5748 16.9517 26.4364 16.7032 26.1893C16.4622 25.9416 16.3256 25.6088 16.3222 25.2611V20.4059H11.9059C11.7343 20.4077 11.564 20.3751 11.4048 20.31C11.2457 20.2448 11.101 20.1483 10.9789 20.0261C10.8569 19.904 10.76 19.7585 10.6939 19.5982C10.6278 19.4379 10.5938 19.2659 10.5938 19.0921C10.5938 18.9184 10.6278 18.7464 10.6939 18.5861C10.76 18.4258 10.8569 18.2803 10.9789 18.1581C11.101 18.0359 11.2457 17.9395 11.4048 17.8743C11.564 17.8091 11.7343 17.7765 11.9059 17.7784H17.6344C17.9651 17.7771 18.2835 17.905 18.5233 18.1354C18.6572 18.2528 18.7633 18.3992 18.8337 18.5638C18.8662 18.6479 18.8944 18.7337 18.9183 18.8208C18.9354 18.9103 18.9449 19.001 18.9466 19.0921V19.0921Z"
          fill="white"
        />
        <path
          d="M24.481 13.4529V27.3435C24.481 27.3662 24.4803 27.3884 24.4796 27.4111C24.4606 27.7541 24.3106 28.0762 24.0612 28.3093C23.8118 28.5424 23.4827 28.6683 23.1433 28.6602C22.804 28.6522 22.481 28.5109 22.2427 28.2663C22.0043 28.0216 21.8693 27.6928 21.8662 27.3493V14.7713H9.48629C9.31517 14.7726 9.14549 14.7397 8.98703 14.6743C8.82856 14.6089 8.68444 14.5125 8.56298 14.3905C8.44153 14.2685 8.34513 14.1233 8.27934 13.9635C8.21356 13.8036 8.17969 13.6321 8.17969 13.4589C8.17969 13.2857 8.21356 13.1143 8.27934 12.9544C8.34513 12.7945 8.44153 12.6494 8.56298 12.5274C8.68444 12.4054 8.82856 12.3089 8.98703 12.2436C9.14549 12.1782 9.31517 12.1452 9.48629 12.1465H23.1736C23.347 12.1467 23.5187 12.1818 23.6786 12.2498C23.8384 12.3178 23.9834 12.4174 24.1048 12.5426L24.1055 12.5419C24.1198 12.5569 24.1339 12.5718 24.148 12.5867C24.175 12.6173 24.2012 12.6493 24.226 12.6827C24.2613 12.7311 24.2934 12.7817 24.3223 12.8342C24.3323 12.852 24.3415 12.8698 24.3499 12.8875C24.3605 12.9089 24.3705 12.9309 24.3797 12.953C24.3952 12.9907 24.4095 13.0298 24.4222 13.0689L24.4229 13.071C24.4414 13.1321 24.4558 13.1943 24.466 13.2573V13.2595C24.4724 13.3014 24.4768 13.3441 24.4796 13.3868C24.4803 13.4088 24.481 13.4308 24.481 13.4529Z"
          fill="white"
        />
        <path
          d="M30.3377 29.8975C30.3377 29.9203 30.337 29.9423 30.3363 29.965C30.3179 30.2738 30.1933 30.5664 29.9841 30.792C29.7749 31.0175 29.4943 31.1619 29.191 31.2001C28.8876 31.2383 28.5806 31.1678 28.3232 31.0009C28.0658 30.8341 27.8742 30.5813 27.7817 30.2864C27.7695 30.247 27.7594 30.2069 27.7513 30.1664C27.7462 30.1449 27.7427 30.1236 27.7392 30.1022C27.7292 30.0364 27.7243 29.9698 27.7244 29.9032L27.696 9.23041V9.12232L7.25646 9.15005C7.08529 9.15145 6.91554 9.11853 6.757 9.0532C6.59846 8.98788 6.45427 8.89143 6.33274 8.76942C6.21122 8.64742 6.11476 8.50227 6.04893 8.34234C5.98311 8.18242 5.94922 8.01089 5.94922 7.83765C5.94922 7.66441 5.98311 7.49288 6.04893 7.33296C6.11476 7.17304 6.21122 7.02789 6.33274 6.90588C6.45427 6.78387 6.59846 6.68743 6.757 6.6221C6.91554 6.55677 7.08529 6.52386 7.25646 6.52525L29.0013 6.49609H29.0161C29.3451 6.49617 29.662 6.62187 29.9035 6.84809C30.1449 7.07431 30.2931 7.3844 30.3185 7.7164C30.3193 7.72636 30.32 7.73702 30.32 7.74769C30.3207 7.76831 30.3228 7.78823 30.3228 7.80885C30.3228 7.83445 30.308 7.85579 30.3073 7.88139L30.3377 29.8975Z"
          fill="white"
        />
        <path
          d="M30.3359 29.9648C30.3188 30.2745 30.1948 30.5683 29.9856 30.7949C29.7764 31.0215 29.4952 31.1666 29.1911 31.2048C28.887 31.243 28.5793 31.172 28.3216 31.0041C28.064 30.8363 27.8727 30.5821 27.7812 30.2861C27.8738 30.581 28.0654 30.8337 28.3228 31.0006C28.5802 31.1675 28.8872 31.2379 29.1905 31.1998C29.4938 31.1616 29.7744 31.0173 29.9836 30.7917C30.1928 30.5662 30.3175 30.2736 30.3359 29.9648Z"
          fill={color}
        />
        <path
          d="M24.4661 13.2566C24.4555 13.1936 24.4407 13.1314 24.4219 13.0703C24.4413 13.1312 24.4561 13.1935 24.4661 13.2566Z"
          fill={color}
        />
        <path
          d="M24.4786 13.3873C24.4757 13.3445 24.4713 13.3018 24.4648 13.2598C24.4716 13.302 24.4762 13.3446 24.4786 13.3873Z"
          fill={color}
        />
        <path
          d="M27.7282 29.9033C27.7282 29.9699 27.7332 30.0365 27.7434 30.1024C27.7324 30.0347 27.7268 29.9662 27.7268 29.8976L27.6992 9.23047L27.7282 29.9033Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2715_2521">
          <rect width="103" height="37.1279" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
VymoLogo.propTypes = {
  dark: PropTypes.bool,
};
VymoLogo.defaultProps = {
  dark: true,
};
export default VymoLogo;
